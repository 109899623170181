import React, { useState } from "react";
import "./OktaSignInWidget.css";
import Login from "./Login";
import BecomeClient from "../BecomeClient";
const OktaSignInWidget = () => {
    return (
        <main className="login container-fluid">
            <div className="login-bg">
                <div className="dummyDiv"></div>
                <Login></Login>
             </div>
        </main>
    );
}
export default OktaSignInWidget;