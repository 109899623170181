import React from "react";
import footerWhiteLogo from "./icons/footer/logo-white-desktop.png";
import LinkedIn from "./icons/footer/linkedIn-logo-desktop.png"
import mortgageBankersLogo from "./icons/footer/mortgage-bankers-association-desktop.png";
import './FooterSection.css'
function FooterSection() {
    return (
      <footer className="footer">
        <div className="footer-header">
          <div className="content-container">
            <div className="footer-header-content gutter d-flex">
              <a className="footer-logo">
                <picture>
                  <source
                    media="(max-width:767px)"
                    srcSet="./images/footer/logo-white-mobile.png"
                  />
                  <source
                    media="(max-width:1024px)"
                    srcset="./images/footer/logo-white-tablet.png"
                  />
                  <img
                    src={footerWhiteLogo}
                    alt="Freedom Mortgage | Wholesale"
                  />
                </picture>
              </a>
              <a className="linked-in-logo">
                <picture>
                  <source
                    media="(max-width:767px)"
                    srcSet="./images/footer/linkedIn-logo-mobile.png"
                  />
                  <source
                    media="(max-width:1024px)"
                    srcSet="./images/footer/linkedIn-logo-tablet.png"
                  />
                  <img alt="LinkedIn" src={LinkedIn} />
                </picture>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-detail">
          <div className="content-container">
            <div className="footer-detail-content gutter d-flex">
              <div className="site-map">
                <h3>Mortgage Products</h3>
                <div>
                  <a href="https://www.freedomwholesale.com/wholesale/mortgage-products/va-loan">VA Loans</a>
                  <a href="https://www.freedomwholesale.com/wholesale/mortgage-products/conventional-loan">Conventional Loans</a>
                  <a href="https://www.freedomwholesale.com/wholesale/mortgage-products/conventional-loan">FHA Loans</a>
                  <a href="https://www.freedomwholesale.com/wholesale/mortgage-products/usda-loan">USDA Loans</a>
                  <a href="https://www.freedomwholesale.com/wholesale/mortgage-products/jumbo-loan">Premier Jumbo</a>
                </div>
              </div>
              <div className="site-map">
                <h3>About Us</h3>
                <div>
                  <a href="https://www.freedommortgage.com/community">In the Community</a>
                  <a href="https://www.freedomwholesale.com/wholesale/about-us">Testimonials</a>
                  <a href="https://blog.freedomwholesale.com/wholesale-blogs">Blog</a>
                  <a href="https://careers.freedommortgage.com/us/en">Careers</a>
                  <a href="https://www.freedomwholesale.com/wholesale/contact-us">Contact Us</a>
                </div>
              </div>
              <div className="site-map">
                <h3>Account</h3>
                <div>
                  <a>Login</a>
                </div>
              </div>
              <div className="site-map"></div>
            </div>
            <div class="address gutter">
              <div>
                <span>
                  951 Yamato Road, Boca Raton, FL 33431 United States | Lender
                  NMLS ID: 2767.
                </span>{" "}
                <a
                  href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2767"
                  target="_blank"
                >
                  NMLS Consumer Access
                </a>
                <span>Equal Housing Opportunity.</span>.
                <img src="https://authoring.freedomwholesale.com/wcm/connect/authoring.freedomwholesale.com31582/0f383acb-cf1c-476a-81a8-b466ed0a4acd/equal-housing.png?MOD=AJPERES&amp" />
              </div>
              <div class="links">
                <a href="https://www.freedomwholesale.com/wholesale/legal">
                  Legal
                </a>{" "}
                <span>|</span>{" "}
                <a href="https://www.freedomwholesale.com/wholesale/privacy-policy">
                  Privacy Notice
                </a>{" "}
                <span>|</span>
                <a href="https://www.freedomwholesale.com/wholesale/state-licensing">
                  State Licensing
                </a>{" "}
                <span>|</span> <span>© Freedom Mortgage Corporation.</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default FooterSection;