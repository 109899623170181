import React from "react";
import logo from "./icons/logo.png";
import phone from "./icons/phone.png";
import "./HeaderBar.css";
function HeaderBar() {
  return (
    <div>
      <header className="header">
        <div className="content-container">
          <div className="login-links d-flex gutter">
            <a className="anchor-link d-flex" href="https://www.freedomwholesale.com/wholesale/contact-us">
              <svg
                className="d-none d-sm-inline-block"
                width="22px"
                height="22px"
                viewBox="0 0 22 22"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>call</title>
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g id="call" fill="#0A4A8D" fillRule="nonzero">
                    <path d="M21.4227104,16.9722097 L16.9698303,14.0024462 C16.4047459,13.6286049 15.647276,13.7516146 15.2295097,14.2851151 L13.9324321,15.9529432 C13.7657381,16.1727539 13.4627243,16.2365144 13.2215764,16.1025485 L12.9748434,15.9665203 C12.1569254,15.5206691 11.1392322,14.9656 9.08876616,12.9142633 C7.03830008,10.8629266 6.48215192,9.84439056 6.03633118,9.02792041 L5.9010426,8.78117053 C5.76523848,8.5400491 5.82779173,8.23572568 6.04767325,8.06768828 L7.71427035,6.77090872 C8.24751956,6.35307089 8.37069274,5.595764 7.99730659,5.03046917 L5.02774606,0.577284688 C4.64508004,0.00163521056 3.8773421,-0.171171342 3.28493368,0.184968269 L1.42290065,1.30357061 C0.837838797,1.64755103 0.40860154,2.20463949 0.225195089,2.85809918 C-0.445318961,5.30136557 0.0591024817,9.51806888 6.2702614,15.7303828 C11.2111083,20.6711808 14.8893335,21.9999432 17.4174984,21.9999432 C17.9993381,22.0023756 18.5789007,21.9266707 19.1405911,21.7747883 C19.794135,21.5915841 20.3512713,21.1622745 20.6950134,20.5770008 L21.8146563,18.7159576 C22.1712871,18.1233798 21.9985783,17.3551167 21.4227104,16.9722097 Z"></path>
                    <path d="M12.3499877,3.69997539 C15.6345728,3.7036258 18.2963332,6.36542721 18.3000246,9.6500123 C18.3000246,9.8433197 18.4567049,10 18.6500123,10 C18.8433197,10 19,9.8433197 19,9.6500123 C18.9959394,5.97897648 16.0210235,3.00401955 12.3499877,3 C12.1566803,3 12,3.1566803 12,3.3499877 C12,3.54329509 12.1566803,3.69997539 12.3499877,3.69997539 Z"></path>
                    <path d="M12.3571309,5.7142618 C14.5257814,5.71681483 16.2831852,7.47421861 16.2857382,9.6428691 C16.2857382,9.84012188 16.4456582,10 16.6428691,10 C16.8401219,10 17,9.84012188 17,9.6428691 C16.9970284,7.07988047 14.9200777,5.0029297 12.3571309,5 C12.1598781,5 12,5.15987812 12,5.3571309 C12,5.55438367 12.1598781,5.7142618 12.3571309,5.7142618 Z"></path>
                    <path d="M12.374989,7.74997803 C13.4099991,7.75120851 14.2487475,8.58995693 14.250022,9.62501099 C14.250022,9.83212727 14.4179387,10 14.625011,10 C14.8320833,10 15,9.83212727 15,9.62501099 C14.9983301,8.1759441 13.8240559,7.00162599 12.374989,7 C12.1678727,7 12,7.16787273 12,7.37498901 C12,7.58210529 12.1678727,7.74997803 12.374989,7.74997803 Z"></path>
                  </g>
                </g>
              </svg>
              <span className="pl-1">Contact Us</span>
            </a>
            <a className="anchor-link d-flex" href="">
              <svg
                width="22px"
                height="22px"
                viewBox="0 0 20 22"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    transform="translate(10.000000, 11.000000) scale(-1, 1) translate(-10.000000, -11.000000) "
                    fill="#0A4A8D"
                    fillRule="nonzero"
                  >
                    <path d="M10,0 C6.69867353,0 4,2.69867353 4,6 C4,9.30132647 6.69867353,12 10,12 C13.3013265,12 16,9.30132647 16,6 C16,2.69867353 13.3013265,0 10,0 Z"></path>
                    <path d="M19.9572539,16.0876072 C19.8052435,15.7196028 19.6026115,15.3761572 19.3746202,15.0572233 C18.2093043,13.3889715 16.4107331,12.2850055 14.3841221,12.015142 C14.13082,11.9906302 13.8521585,12.0396537 13.649478,12.1868648 C12.5855024,12.9473851 11.3188948,13.3399011 10.0015685,13.3399011 C8.68424226,13.3399011 7.41763462,12.9473851 6.35365899,12.1868648 C6.1509785,12.0396537 5.87231706,11.9660716 5.61901493,12.015142 C3.59240391,12.2850055 1.7684734,13.3889715 0.628516824,15.0572233 C0.400525509,15.3761572 0.197845013,15.7441615 0.0458831286,16.0876072 C-0.0300978135,16.2348183 -0.00478699551,16.4065411 0.0711939466,16.5537522 C0.273874443,16.8971979 0.527176576,17.2406905 0.75516789,17.5350657 C1.10981027,18.0012108 1.48981196,18.4182385 1.92048377,18.8107545 C2.27512615,19.1542002 2.68043865,19.4731341 3.08579965,19.7920681 C5.08705135,21.2395266 7.49366405,22 9.9762577,22 C12.4588513,22 14.865464,21.2394797 16.8667157,19.7920681 C17.2720282,19.4976928 17.6773408,19.1542002 18.0320316,18.8107545 C18.4373441,18.4182385 18.8426566,18.0011638 19.1973475,17.5350657 C19.4506496,17.2161318 19.6786894,16.8971979 19.8813214,16.5537522 C20.007924,16.4065411 20.0332348,16.2347713 19.9572539,16.0876072 Z"></path>
                  </g>
                </g>
              </svg>
              <span className="pl-1">Login</span>
            </a>
          </div>
          <div className="main-nav gutter">
            <a className="logo">
              <img src={logo} />
            </a>
            <nav>
              <ul className="nav-list">
                <li>
                  <a className="anchor-link" href="https://www.freedomwholesale.com/wholesale/become-a-client">Become a Client</a>
                </li>
                <li>
                  <a className="anchor-link" href="https://www.freedomwholesale.com/wholesale/mortgage-products">Mortgage Products</a>
                </li>
                <li>
                  <a className="anchor-link" href="https://www.freedomwholesale.com/wholesale/lending-solutions">Lending Solutions</a>
                </li>
                <li>
                  <a className="anchor-link" href="https://www.freedomwholesale.com/wholesale/featured-news">Featured News</a>
                </li>
                <li>
                  <a className="anchor-link" href="https://www.freedomwholesale.com/wholesale/about-us">About Us</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      {/* Mobile Header */}
      <header className="mobile-header"></header>
    </div>
  );
}
export default HeaderBar;
