import logo from './logo.svg';
import './App.css';
import HeaderBar from "./components/HeaderBar";
import OktaSignInWidget from "./components/auth/OktaSignInWidget";
import { Link, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import FooterSection from './components/FooterSection';
function App() {
  return (
    <div>
      <HeaderBar></HeaderBar>
      <OktaSignInWidget></OktaSignInWidget>
      <FooterSection></FooterSection>
    </div>
  );
}

export default App;
