import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import config from "../../config";
import BecomeClient from "../BecomeClient";
const Login = ({ onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }
    var widget;
    var location;
    if (window.location.href.includes("clientdevcf")) {
      widget = new OktaSignIn(config.devConfig.widget);
      location = "dev";
    } else if (window.location.href.includes("clienttestcf")) {
      widget = new OktaSignIn(config.testConfig.widget);
      location = "test";
    } else if (window.location.href.includes("clientuatcf")) {
      widget = new OktaSignIn(config.uat.widget);
      location = "uat";
    }else if (window.location.href.includes("clientuatbcf")) {
    widget = new OktaSignIn(config.uatb.widget);
    location = "uat";
    } else if (window.location.href.includes("clientcf")) {
      widget = new OktaSignIn(config.prodConfig.widget);
      location = "prod";
    } else {
      widget = new OktaSignIn(config.localhost.widget);
      location = "local";
    }
     var cookies = document.cookie.split(";");
     for (var i = 0; i < cookies.length; i++) {
       var spcook = cookies[i].split("=");
       document.cookie = spcook[0] + "=;expires=Thu, 21 Sep 1979 00:00:01 UTC;";
     }
    //comment
    // Search for URL Parameters to see if a user is being routed to the application to recover password
    var searchParams = new URL(window.location.href).searchParams;
    widget.otp = searchParams.get("otp");
    widget.state = searchParams.get("state");
    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess => {
        console.log(onSuccess);
        if (onSuccess) {
          document.cookie = "whlOkta" + "=" + 'Bearer ' + onSuccess.accessToken.accessToken+";path=/;domain=.freedomwholesale.com";
          window.location = window.location.origin + "/#/dashboard";
          // if (location === "dev") {
            //   window.location = "https://clientdevcf.freedomwholesale.com/#/dashboard";
          // } else if (location === "test") {
            //   window.location = "https://clienttestcf.freedomwholesale.com/#/dashboard";
          // } else {
            //   window.location = "http://localhost:8080/#/dashboard";
          // }
          
        }
        })
        .catch(onError);
      
    return () => widget.remove();
  }, [onSuccess, onError]);
  return (
    <div className="loginWrapper">
      <div ref={widgetRef} className="widgetWrapper" />
      <BecomeClient className="beClient"></BecomeClient>
      <div className="info">
        <p style={{ fontWeight: "500", fontSize: "medium" }}>
          {/* Existing Clients: Please contact ServiceDeskWsale@FreedomMortgage.com{" "}
          <br></br>
          to add, remove or update access levels for your staff. */}
          {/* Having trouble logging in?  Please contact the Freedom Wholesale Service Desk at servicedeskwsale@freedommortgage.com.
          <br></br>
        Existing clients: Please contact the Service Desk to add, remove, or update access levels for your staff. */}
          IMPORTANT: The Freedom Wholesale login process has been updated. Going
          forward, you will log into our system using your email address, and a
          verification code will be sent to the email address or cell phone
          number currently in your account. If you access our website via a
          bookmark, please update that link to
          https://clientcf.freedomwholesale.com/Login/index.html to ensure you're
          reaching our new login page. If you have issues logging in, please
          contact the Service Desk at servicedeskwsale@freedommortgage.com.
        </p>
      </div>
    </div>
  );
};

export default Login;
