
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  localhost: {
    oidc: {
      issuer: "https://ciamdev.freedomwholesale.com/oauth2/default",
      clientId: "0oa9mfj5gnKRdzFke1d7",
      scopes: ["openid", "profile", "email"],
      redirectUri: "http://localhost:3000/",
      useClassicEngine: true,
    },
    widget: {
      issuer: "https://ciamdev.freedomwholesale.com/oauth2/default",
      clientId: "0oa9mfj5gnKRdzFke1d7",
      redirectUri: "http://localhost:3000/",
      i18n: {
        en: {
          "primaryauth.username.placeholder": "Email Address",
          "errors.E0000004": "Invalid username or password. Please try again.",
          "error.auth.lockedOut":
            "Your account is locked. Please check your email for additional information.",
        },
      },
      scopes: ["openid", "profile", "email"],
      useClassicEngine: true,
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
  },
  devConfig: {
    isProduction: "false",
    environment: "dev",
    oidc: {
      issuer: "https://freedomwholesale.oktapreview.com/oauth2/default",
      clientId: "0oaa6u7uiteHqjmnH1d7",
      scopes: ["openid", "profile", "email"],
      redirectUri: "https://clientdevcf.freedomwholesale.com/Login/index.html",
      useClassicEngine: true,
      i18n: {
        en: {
          "primaryauth.username.placeholder": "Email Address",
          "errors.E0000004": "Invalid username or password. Please try again.",
          "error.auth.lockedOut":
            "Your account is locked. Please check your email for additional information.",
        },
      },
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
    widget: {
      issuer: "https://freedomwholesale.oktapreview.com/oauth2/default",
      clientId: "0oaa6u7uiteHqjmnH1d7",
      redirectUri: "https://clientdevcf.freedomwholesale.com/Login/index.html",
      scopes: ["openid", "profile", "email"],
      useClassicEngine: true,
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
  },
  testConfig: {
    isProduction: "false",
    environment: "test",
    oidc: {
      issuer: "https://identitytest.freedomwholesale.com/oauth2/default",
      clientId: "0oaaj0ov0jP3khWki1d7",
      scopes: ["openid", "profile", "email"],
      redirectUri: "https://clienttestcf.freedomwholesale.com/Login/index.html",
      useClassicEngine: true,
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
    widget: {
      issuer: "https://identitytest.freedomwholesale.com/oauth2/default",
      clientId: "0oaaj0ov0jP3khWki1d7",
      redirectUri: "https://clienttestcf.freedomwholesale.com/Login/index.html",
      scopes: ["openid", "profile", "email"],
      useClassicEngine: true,
      i18n: {
        en: {
          "primaryauth.username.placeholder": "Email Address",
          "errors.E0000004": "Invalid username or password. Please try again.",
          "error.auth.lockedOut":
            "Your account is locked. Please check your email for additional information.",
        },
      },
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
  },
  uat: {
    isProduction: "false",
    environment: "test",
    oidc: {
      issuer: "https://identityuat.freedomwholesale.com/oauth2/default",
      clientId: "0oab0g5ff2nTX4hHw1d7",
      scopes: ["openid", "profile", "email"],
      redirectUri: "https://clientuatcf.freedomwholesale.com/Login/index.html",
      useClassicEngine: true,
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
    widget: {
      issuer: "https://identityuat.freedomwholesale.com/oauth2/default",
      clientId: "0oab0g5ff2nTX4hHw1d7",
      redirectUri: "https://clientuatcf.freedomwholesale.com/Login/index.html",
      scopes: ["openid", "profile", "email"],
      useClassicEngine: true,
      i18n: {
        en: {
          "primaryauth.username.placeholder": "Email Address",
          "errors.E0000004": "Invalid username or password. Please try again.",
          "error.auth.lockedOut":
            "Your account is locked. Please check your email for additional information.",
        },
      },
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
  },
  uatb: {
    isProduction: "false",
    environment: "test",
    oidc: {
      issuer: "https://identityuat.freedomwholesale.com/oauth2/default",
      clientId: "0oac8wg0tutXe8rHV1d7",
      scopes: ["openid", "profile", "email"],
      redirectUri: "https://clientuatbcf.freedomwholesale.com/Login/index.html",
      useClassicEngine: true,
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
    widget: {
      issuer: "https://identityuat.freedomwholesale.com/oauth2/default",
      clientId: "0oac8wg0tutXe8rHV1d7",
      redirectUri: "https://clientuatbcf.freedomwholesale.com/Login/index.html",
      scopes: ["openid", "profile", "email"],
      useClassicEngine: true,
      i18n: {
        en: {
          "primaryauth.username.placeholder": "Email Address",
          "errors.E0000004": "Invalid username or password. Please try again.",
          "error.auth.lockedOut":
            "Your account is locked. Please check your email for additional information.",
        },
      },
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
  },
  prodConfig: {
    isProduction: "true",
    environment: "test",
    oidc: {
      issuer: "https://identity.freedomwholesale.com/oauth2/default",
      clientId: "0oa99a6ambPLraImc697",
      scopes: ["openid", "profile", "email"],
      redirectUri: "https://clientcf.freedomwholesale.com/Login/index.html",
      useClassicEngine: true,
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
    widget: {
      issuer: "https://identity.freedomwholesale.com/oauth2/default",
      clientId: "0oa99a6ambPLraImc697",
      redirectUri: "https://clientcf.freedomwholesale.com/Login/index.html",
      scopes: ["openid", "profile", "email"],
      useClassicEngine: true,
      i18n: {
        en: {
          "primaryauth.username.placeholder": "Email Address",
          "errors.E0000004": "Invalid username or password. Please try again.",
          "error.auth.lockedOut":
            "Your account is locked. Please check your email for additional information.",
        },
      },
      features: {
        showPasswordToggleOnSignInPage: true,
        hideSignOutLinkInMFA: false,
        rememberMe: false,
      },
    },
  },
};