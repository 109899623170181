import React from "react";
import "./BecomeClient.css";
import desktop from "./icons/desktop.png";
function BecomeClient() { 
  const getStarted =()=>{
    window.location.href ="https://www.freedomwholesale.com/wholesale/become-a-client"
  }
    return (
      <div className="col-xs-12 col-md-5 become-a-client-col beClient">
        <section>
          <div className="become-a-client">
            <div className="banner-section bc become-a-client-banner">
              <div className="">
                <div className="banner-caption">
                  <div className="content-container">
                    <div className="banner-content gutter">
                      <h2 className="banner-title">Become a Client</h2>
                      <p className="banner-description">
                        Get the edge you need with the Freedom Mortgage
                        Wholesale Division.
                      </p>
                      <button className="primary-btn get-started" onClick={getStarted}>
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
                <picture className="banner-img">
                  <source
                    media="(max-width:768px)"
                    srcSet="./icons/tablet/tablet.png"
                  ></source>
                  <source
                    media="(max-width:1024px)"
                    srcSet="./icons/tablet/tablet.png"
                  ></source>
                  <img src={desktop} alt="Login Banner" />
                </picture>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}
export default BecomeClient;